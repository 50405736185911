<template>
	<el-select
			:value="value"
			v-loading="loading"
			@change="emit"
			clearable
			@clear="emit(null)"
			filterable
			:placeholder="$t('select.selectMesta')">
		<el-option
				v-for="val in popustiModel"
				:key="val.model"
				:value="val.model"
				:label="val.naziv">
			<el-tooltip placement="top" v-if="val.opis">
				<div slot="content" style="max-width: 500px">{{val.opis}}</div>
				<span>{{val.naziv}}</span>
			</el-tooltip>
			<span v-else>{{val.naziv}}</span>
		</el-option>
	</el-select>
</template>

<script>
export default {
	name: 'select-popusti-model',
	props: ['value'],
	data() {
		return {
			popustiModel: [],
			loading: true
		};
	},
	mounted() {
		this.$get('popustiModeliPopusta').then(data => {
			this.popustiModel = data;
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
		}
	}
};
</script>

