<template>
	<el-form class="page" :model="popusti" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.naziv')" prop="naziv">
					<el-input v-model="popusti.naziv"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.model')" class="width-full" prop="model">
					<select-popusti v-model="popusti.model"></select-popusti>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.linije')" class="width-full" prop="id_linije">
					<select-linije v-model="popusti.id_linije" :multiple="true"></select-linije>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.aktivno')">
					<el-switch
							v-model="popusti.aktivno"
							active-value="DA"
							inactive-value="NE"
							:active-text="$t('global.aktivan')"
							:inactive-text="$t('global.neaktivan')"
							active-color="#13ce66"
							inactive-color="#ff4949"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.datumOd')" class="width-full">
					<el-date-picker
							v-model="popusti.datum_od"
							type="date"
							:clearable="false"
							:editable="false"
							value-format="yyyy-MM-dd"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.datumDo')" class="width-full">
					<el-date-picker
							v-model="popusti.datum_do"
							type="date"
							:clearable="false"
							:editable="false"
							value-format="yyyy-MM-dd"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3" v-if="popusti.model !== 'akcija' && popusti.model !== 'kolicinski'">
				<el-form-item :label="$t('popusti.vremeOd')" class="width-full">
					<el-time-select
							v-model="popusti.vreme_od"
							:picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"></el-time-select>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3" v-if="popusti.model !== 'akcija' && popusti.model !== 'kolicinski'">
				<el-form-item :label="$t('popusti.vremeDo')" class="width-full">
					<el-time-select
							v-model="popusti.vreme_do"
							:picker-options="{ start: '00:00', step: '00:01', end: '24:00' }"></el-time-select>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3" v-if="popusti.model !== 'kolicinski'">
				<el-form-item :label="$t('popusti.fiksniPopust')" class="width-full">
					<el-input-number :min="0" v-model="popusti.fiksni_popust"></el-input-number>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3" v-if="popusti.model !== 'kolicinski'">
				<el-form-item :label="$t('popusti.procenatPopust')" class="width-full">
					<el-input-number :min="0" v-model="popusti.procenat_popust"></el-input-number>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3" v-if="popusti.model !== 'kolicinski'">
				<el-form-item :label="$t('popusti.fiksnaCena')" class="width-full">
					<el-input-number :min="0" v-model="popusti.fiksna_cena"></el-input-number>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="3">
				<el-form-item :label="$t('popusti.minCena')" class="width-full">
					<el-input-number :min="1" v-model="popusti.min_cena"></el-input-number>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.pravac')" class="width-full">
					<el-select v-model="popusti.pravac">
						<el-option
								key="0"
								:label="$t('popusti.pravacOba')"
								:value="0">
						</el-option>
						<el-option
								key="1"
								:label="$t('popusti.pravacIzSrb')"
								:value="11">
						</el-option>
						<el-option
								key="2"
								:label="$t('popusti.pravacKaSrb')"
								:value="21">
						</el-option>
						<el-option
								key="3"
								:label="$t('popusti.povratnaIzSrb')"
								:value="12">
						</el-option>
						<el-option
								key="4"
								:label="$t('popusti.povratnaKaSrb')"
								:value="22">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.kartaDatumOd')" class="width-full">
					<el-date-picker
							v-model="popusti.karta_datum_od"
							type="date"
							:clearable="false"
							:editable="false"
							value-format="yyyy-MM-dd"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('popusti.kartaDatumDo')" class="width-full">
					<el-date-picker
							v-model="popusti.karta_datum_do"
							type="date"
							:clearable="false"
							:editable="false"
							value-format="yyyy-MM-dd"
							format="dd.MM.yyyy">
					</el-date-picker>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row v-if="popusti.model === 'kolicinski'">
			<el-col>
				<el-form-item>
					<el-button type="success" @click="addKolicineParametri()"><i class="fas fa-plus"></i> {{ $t('global.dodaj') }}</el-button>
					<el-table
							v-if="popusti.kolicine_parametri.length"
							:data="popusti.kolicine_parametri"
							ref="table"
							border
							stripe>
						<el-table-column
								align="center"
								:label="$t('popusti.brojKarata')">
							<template slot-scope="scope">
								<el-input-number :min="1" @change="proveraDuplih()" v-model="scope.row.broj_karata"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column
								align="center"
								:label="$t('popusti.fiksniPopust')">
							<template slot-scope="scope">
								<el-input-number :min="0" @change="resetPodataka(scope.row, ['procenat_popust', 'fiksna_cena'])" v-model="scope.row.fiksni_popust"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column
								align="center"
								:label="$t('popusti.procenatPopust')">
							<template slot-scope="scope">
								<el-input-number :min="0" @change="resetPodataka(scope.row, ['fiksni_popust', 'fiksna_cena'])" v-model="scope.row.procenat_popust"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column
								align="center"
								:label="$t('popusti.fiksnaCena')">
							<template slot-scope="scope">
								<el-input-number :min="0" @change="resetPodataka(scope.row, ['fiksni_popust', 'procenat_popust'])" v-model="scope.row.fiksna_cena"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column
								width="100"
								align="center">
							<template slot-scope="scope">
								<el-button @click="popusti.kolicine_parametri.splice(scope.$index, 1)" type="danger" icon="el-icon-delete"/>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6" v-if="popusti.pravac === 0 || popusti.pravac === 11 || popusti.pravac === 12">
				<el-form-item :label="$t('popusti.dani')">
					<el-checkbox-group v-model="popusti.dani" size="mini">
						<el-checkbox-button v-for="dan in daniLista" :label="dan" :key="dan">{{$t('global.daniSkraceno.' + dan)}}</el-checkbox-button>
					</el-checkbox-group>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6" v-if="popusti.pravac === 0 || popusti.pravac === 21 || popusti.pravac === 22">
				<el-form-item :label="$t('popusti.daniPovratak')">
					<el-checkbox-group v-model="popusti.dani_povratak" size="mini">
						<el-checkbox-button v-for="dan in daniLista" :label="dan" :key="dan">{{$t('global.daniSkraceno.' + dan)}}</el-checkbox-button>
					</el-checkbox-group>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'popusti-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			daniLista: [1, 2, 3, 4, 5, 6, 7],
			popusti: {
				naziv: '',
				model: 'akcija',
				datum_od: new Date(),
				datum_do: new Date(),
				fiksni_popust: null,
				procenat_popust: null,
				fiksna_cena: null,
				min_cena: null,
				id_linije: [],
				pravac: 11, // 11 | 12 | 21 | 22
				kolicine_parametri: [{
					broj_karata: 1,
					fiksni_popust: 0,
					procenat_popust: 0,
					fiksna_cena: 0
				}],
				vreme_od: '00:00',
				vreme_do: '24:00',
				dani: [],
				dani_povratak: [],
				karta_datum_od: new Date(),
				karta_datum_do: new Date(),
				aktivno: 'DA'
			},
			rules: {
				naziv: [{ required: true, max: 250, trigger: 'blur', message: this.$t('message.obavezno') }],
				model: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	watch: {
		'popusti.fiksni_popust'(val) {
			if (val > 0) {
				this.popusti.procenat_popust = 0;
				this.popusti.fiksna_cena = 0;
			}
		},
		'popusti.procenat_popust'(val) {
			if (val > 0) {
				this.popusti.fiksni_popust = 0;
				this.popusti.fiksna_cena = 0;
			}
		},
		'popusti.fiksna_cena'(val) {
			if (val > 0) {
				this.popusti.procenat_popust = 0;
				this.popusti.fiksni_popust = 0;
			}
		}
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('popusti.popusti')
		});

		this.$utils('stopLoadingAfter', [this.getPopusti()]);
	},
	methods: {
		getPopusti() {
			if (!this.formCreate) {
				return this.$get('popusti', { id: this.id }).then(data => {
					this.popusti = data;
					if (!_.isArray(this.popusti.kolicine_parametri)) {
						this.popusti.kolicine_parametri = [{
							broj_karata: 1,
							fiksni_popust: 0,
							procenat_popust: 0,
							fiksna_cena: 0
						}];
					}
				});
			}
		},
		onSubmit() {
			if (!this.proveraDuplih()) {
				this.onSubmitLoading = false;
				return false;
			}
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.popusti);

				if (this.formCreate)
					this.$save('popusti', data);
				else
					this.$update('popusti', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		},
		addKolicineParametri() {
			let maxBrojKarate = _.maxBy(this.popusti.kolicine_parametri, 'broj_karata');
			this.popusti.kolicine_parametri.push({
				broj_karata: 1 + (maxBrojKarate.broj_karata || null),
				fiksni_popust: 0,
				procenat_popust: 0,
				fiksna_cena: 0
			});
		},
		resetPodataka(row, arrPodaci) {
			if (_.isArray(arrPodaci)) {
				arrPodaci.forEach(val => {
					row[val] = 0;
				});
			}
		},
		proveraDuplih() {
			let dupli = _.filter(this.popusti.kolicine_parametri, v => _.filter(this.popusti.kolicine_parametri, v1 => v1.broj_karata === v.broj_karata).length > 1);
			if (dupli.length) {
				this.$alert(this.$t('popusti.postojeDupli'), this.$t('confirm.upozorenje'), {
					confirmButtonText: this.$t('global.uredu')
				});
				return false;
			}
			return true;
		}
	},
	components: {
		selectPopusti: require('../../components/select/popustiModeli').default,
		selectLinije: require('../../components/select/linije').default
	}
};
</script>
